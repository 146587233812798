import axios from 'axios';
import { XOR } from 'ts-essentials';

export const loadSdk = ({ id, src }: { id: string; src: string }): Promise<unknown> => {
  return new Promise((resolve, _reject) => {
    const tags = document.querySelectorAll(`script#${id}`);
    if (tags) {
      tags.forEach((tag) => tag.remove());
    }
    const scriptTag: HTMLScriptElement = document.createElement('script');
    scriptTag.id = id;
    scriptTag.src = src;
    scriptTag.onload = resolve;
    document.body.append(scriptTag);
  });
};

// https://opentutorials.org/module/938/7369
// protocol: 'http:'
// host: 'host.com:8080' ---- complex
// slashes: true
// auth: 'user:password'
// port: '8080'
// hostname: 'host.com'
// search: '?query=string' ---- complex
// hash: '#hash'
// query: 'query=string'
// pathname: '/p/a/t/h'
// path: '/p/a/t/h?query=string' ---- complex
// href: 'http://user:password@host.com:8080/p/a/t/h?query=string#hash' ---- complex

type BuildUrlPropsQuery = [string, string][] | Record<string, string | number>;

interface Path {
  path?: string;
}
interface PathElements {
  params?: string[] | string;
  query?: BuildUrlPropsQuery;
  pathname?: string;
  search?: string;
  hash?: string;
}

type PartialBuilUrlProps = {
  protocol?: Protocol;
  auth?: Auth;
  host: string;
  port?: string | number;
};

type BuildUrlProps = PartialBuilUrlProps & XOR<Path, PathElements>;
type ProtocolType = 'https' | 'http' | 'ftp';
type Protocol = `${ProtocolType}://` | '';
type Auth = `${string}:${string}` | '';

export const buildUrl = ({
  protocol = '', // `https://`
  auth = '', // `user:password`
  host,
  params = [],
  query = [],
  hash = '',
}: BuildUrlProps): string => {
  const queryArr = Array.isArray(query) ? query : Object.entries(query).sort();
  const paramsArr = typeof params === 'string' ? [params] : params;

  let href = '';
  href += protocol;
  href += auth;
  href += host;
  href += paramsArr.join('/');
  href += (queryArr.length > 0 ? '?' : '') + queryArr.join('&').replace(',', '=');
  href += (hash ? '#' : '') + hash;
  return href;
};

export const fetcher = async <Response = void, Payload = void, Error = void>(
  url: string,
  payload?: Payload
) => {
  url = buildUrl({ host: process.env.NEXT_PUBLIC_REST_API_URI ?? '', path: url });
  // console.log('url', url);
  // console.log('payload', payload);
  axios.get;
  const res = payload
    ? await axios.post<Response, Error>(url, payload)
    : await axios.get<Response, Error>(url);
  // console.log({ res });
  return res;
};
