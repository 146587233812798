import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useRouter } from 'next/router';

type User = null | {
  userName: string;
  email: string;
};

type Error = null | unknown;

type AuthContextType = {
  user: User;
  error: Error;
  register: (registerParams: RegisterParams) => Promise<void>;
  login: () => Promise<void>;
  logout: () => Promise<void>;
  checkUserLoggedIn: (user: User) => Promise<void>;
};

type RegisterParams = {
  userName: string;
  email: string;
  password: string;
};

export const AuthContext = createContext<AuthContextType>({
  user: null,
  error: null,
  register: async () => undefined,
  login: async () => undefined,
  logout: async () => undefined,
  checkUserLoggedIn: async (user: User) => undefined,
});

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [user, setUser] = useState<User>(null);
  const [error, setError] = useState<Error>(null);

  useEffect(() => {
    (async () => {
      const baseUrl = process.env.NEXT_PUBLIC_REST_API_URI;

      try {
        const res = await axios.get(`${baseUrl}/api/user/profile`, {
          withCredentials: true,
        });
        const newUserInfo = res.data.data;
        if (JSON.stringify(user) != JSON.stringify(newUserInfo)) {
          setUser(newUserInfo);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [user]);

  // register user
  const register = async ({ userName, email, password }: RegisterParams) => {
    console.log(user);
  };

  // login user
  const login = async () => {
    console.log('login');
  };

  // logout user
  const logout = async () => {
    console.log('logout');
  };

  // check if user if logged in
  const checkUserLoggedIn = async (user: User) => {
    console.log('check login');
  };

  return (
    <>
      <AuthContext.Provider value={{ user, error, register, login, logout, checkUserLoggedIn }}>
        {children}
      </AuthContext.Provider>
    </>
  );
};

export const useAuth = () => {
  const ctx = useContext(AuthContext);
  return { ...ctx };
};
