// https://velog.io/@mnz/Next.js-Next-%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8%EC%97%90%EC%84%9C-%EB%9D%BC%EC%9D%B4%EB%B8%8C%EB%9F%AC%EB%A6%AC-%EC%97%86%EC%9D%B4-Google-Analytics-%EC%A0%81%EC%9A%A9%ED%95%98%EA%B8%B0
// https://www.freecodecamp.org/news/performance-and-user-tracking-in-react-with-google-analytics/

declare global {
  interface Window {
    gtag: (param1: 'config' | 'event' | 'set', param2: string, param3: object) => void;
    Kakao: any;
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  if (process.env.NEXT_PUBLIC_GA_TRACKING_ID) {
    window.gtag('config', process.env.NEXT_PUBLIC_GA_TRACKING_ID, { page_path: url });
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
interface GAEvent {
  action: string;
  category: string;
  label: string;
  value: number;
}

// event types
// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: GAEvent) => {
  console.info(action);
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

interface GATiming {
  name: string;
  category?: string;
  label?: string;
}

export const timing = ({ name, category, label }: GATiming) => {
  // Feature detects Navigation Timing API support.
  if (window.performance) {
    // Gets the number of milliseconds since page load
    // (and rounds the result since the value must be an integer).
    const timeSincePageLoad = Math.round(performance.now());

    // Sends the timing event to Google Analytics.
    // https://developers.google.com/analytics/devguides/collection/gtagjs/user-timings
    window.gtag('event', 'timing_complete', {
      name,
      value: timeSincePageLoad,
      event_category: category ?? 'JS Dependencies',
      event_label: label,
    });
  }
};
