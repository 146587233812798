import { createTheme, ThemeOptions } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { koKR as koKRCore } from '@mui/material/locale';

const localeCore = koKRCore;

// 모든 theme은 여기서 결정
// https://bareynol.github.io/mui-theme-creator/
// ******************************
// theme default 값
// https://mui.com/customization/default-theme/
// ******************************
// sx(시스템 키)와 css 프로퍼티간 맵핑 테이블
// https://mui.com/system/properties/
// ******************************
// theme 필드
// https://mui.com/customization/theming/#theme-configuration-variables

// https://fonts.google.com/noto/specimen/Noto+Sans+KR
// 100: Thin
// 300: Light
// 400: Regular
// 500: Medium
// 700: Bold
// 900: Black

export const muiThemeOptions: ThemeOptions = {
  // breakpoints: {},
  // direction: 'ltr',
  // mixins: {},
  // shadows: {},
  // shape: {},
  spacing: 8,
  // transitions: {},
  // zIndex: {},
  typography: {
    // 총 13가지의 variants를 가짐
    // h1,2,3,4,5,6(<h>)
    // subtitle1,2(<h>)
    // body1,2(<p>)
    // button,caption,overline(<span>)
    h1: { fontSize: '3.0rem', '@media (min-width:600px)': { fontSize: '3.0rem' } },
    h2: { fontSize: '3.0rem', '@media (min-width:600px)': { fontSize: '3.0rem' } },
    fontFamily: [
      'Noto Sans KR',
      'Roboto',
      'sans-serif',
      // '-apple-system',
      // 'BlinkMacSystemFont',
      // '"Segoe UI"',
      // '"Helvetica Neue"',
      // 'Arial',
      // '"Apple Color Emoji"',
      // '"Segoe UI Emoji"',
      // '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    // mode: 'light',
    primary: {
      // main: '#556cd6',
      main: '#002366',
    },
    secondary: {
      main: '#19857b',
      // main: '#efefef',
    },
    error: {
      main: red.A400,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // subtitle1: 'h2',
          // subtitle2: 'h2',
          // body1: 'span',
          // body2: 'span',
          // button: 'button',
          // caption: 'caption',
          // overline: 'div',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // ellipsis 효과를 위한 style set
          display: 'block',
          wordBreak: 'keep-all',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textAlign: 'center',
          fontSize: '1rem',
        },
      },
      defaultProps: {
        // The props to change the default for.
        disableRipple: false, // No more ripple!
      },
    },
  },
};

export const muiTheme = createTheme(muiThemeOptions, localeCore);
