import React, { useEffect, useMemo, useState } from 'react';
import type { AppProps } from 'next/app';
import { ThemeProvider as MuiThemeProvider, createTheme, ThemeOptions } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { createEmotionCache } from '@/styles/createEmotionCache';
import { useRouter } from 'next/router';
import type { ReactElement, ReactNode } from 'react';
import type { NextPage } from 'next';
import { AuthProvider } from '@/context/AuthContext';
import { ColorModeContext } from '@/context/ColorContext';
import { muiThemeOptions } from '@/styles/theme';
import Head from 'next/head';
import { pageview } from '@/utils/ga';
import { loadSdk } from '@/utils/common';
import { sdkList } from '@/utils/sdk';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  Component: NextPageWithLayout;
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export default function MyApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: MyAppProps) {
  const router = useRouter();

  // ******************************
  // Dark Mode
  // ******************************
  const [darkMode, setDarkMode] = useState<boolean>(false);

  const colorMode = useMemo(() => {
    return {
      toggleDarkMode: () => {
        setDarkMode(!darkMode);
      },
      darkMode,
    };
  }, [darkMode]);

  const theme = useMemo<ThemeOptions>(() => {
    if (muiThemeOptions?.palette) {
      muiThemeOptions.palette.mode = darkMode ? 'dark' : 'light';
      return createTheme(muiThemeOptions);
    }
    return createTheme({});
  }, [darkMode]);

  useEffect(() => {
    const sdk = async () => {
      const { kakao } = sdkList;
      await loadSdk(kakao);
      window.Kakao.isInitialized() ? {} : window.Kakao.init('37fde1b7066ebcc252075ac93855d5c9');
    };
    sdk();
  }, []);

  // ******************************
  // Google Analytics
  // ******************************
  useEffect(() => {
    router.events.on('routeChangeComplete', pageview);
    return () => {
      router.events.off('routeChangeComplete', pageview);
    };
  }, [router.events]);

  // ******************************
  // Common Layout
  // ******************************
  const getLayout = Component.getLayout ?? ((page: ReactElement) => page);

  return (
    <AuthProvider>
      <ColorModeContext.Provider value={colorMode}>
        <CacheProvider value={emotionCache}>
          <Head>
            <meta name='viewport' content='initial-scale=1, width=device-width' />
          </Head>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            {getLayout(<Component {...pageProps} />)}
          </MuiThemeProvider>
        </CacheProvider>
      </ColorModeContext.Provider>
    </AuthProvider>
  );
}
